import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";

import Button from "../../../shared/Button/Button";
import { Language } from "../../../../types/types";
import { t } from "../../../../utils/globalUtils";
import {
	addLanguageDocument,
	deleteLanguageDocument,
} from "../../../../firebase/languagesServices";
import { setLanguageId } from "../../../../redux/languages";
import { LANGUAGES } from "./languagesConstants";

import "./Languages.css";

const Languages = () => {
	const dispatch = useDispatch();

	const languages = useSelector((state: any) => state.languages.value);
	const selectedLanguage = useSelector((state: any) => state.selectedLanguage.value);
	const translations = useSelector((state: any) => state.translations.value);

	const [languageToAdd, setLanguageToAdd] = useState<any>();

	const languagesOptions = LANGUAGES.map((l) => ({ value: l.code, label: l.name })) as any[];

	const addLanguage = () => {
		if (languageToAdd)
			addLanguageDocument({ code: languageToAdd.value, name: languageToAdd.label }).then(
				(id) => dispatch(setLanguageId({ code: languageToAdd.value, id }))
			);
	};

	const deleteLanguage = (id: string | undefined) => {
		deleteLanguageDocument(id);
	};

	return (
		<div id="languages">
			<div id="languagesList">
				{languages?.map((language: Language) => (
					<div className="language">
						<div className="languageCode">{language.code}</div>
						<div>{language.name}</div>
						<FontAwesomeIcon
							className="languageDeleteButton pointer"
							icon={faXmark}
							onClick={() => deleteLanguage(language.id)}
						/>
					</div>
				))}
			</div>
			<div id="addLanguage">
				<div id="addLanguageForm">
					<Select
						className="formSelect"
						options={languagesOptions}
						value={languageToAdd}
						onChange={setLanguageToAdd}
					/>
				</div>
				<Button
					text={t(translations, selectedLanguage, "addLanguage")}
					onClick={addLanguage}
					className="blue"
				/>
			</div>
		</div>
	);
};
export default Languages;
