import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Button from "../shared/Button/Button";
import Contact from "../Contact/Contact";
import { isMobileDevice, t } from "../../utils/globalUtils";
import { getHomeProduct1ImgFile, getHomeProduct2ImgFile } from "../../firebase/allService";
import { PRODUCTS } from "../../assets/constants/constants";
import section1ImgSrc from "../../assets/img/home/section1.svg";
import section2ImgSrc from "../../assets/img/home/section2.svg";
import section3ImgSrc from "../../assets/img/home/section3.svg";
import section4ImgSrc from "../../assets/img/home/section4.svg";
import cartozincLogoImgSrc from "../../assets/img/logo_cartozinc.svg";
import validIconImgSrc from "../../assets/img/home/validIcon.svg";
import invalidIconImgSrc from "../../assets/img/home/invalidIcon.svg";

import "./Home.css";

const HOME_SECTIONS_DATA = [
	{
		titleKey: "homeTitle1",
		contentKey: "homeContent1",
		imgSrc: section1ImgSrc,
	},
	{
		titleKey: "homeTitle2",
		contentKey: "homeContent2",
		imgSrc: section2ImgSrc,
	},
	{
		titleKey: "homeTitle3",
		contentKey: "homeContent3",
		imgSrc: section3ImgSrc,
	},
	{
		titleKey: "homeTitle4",
		contentKey: "homeContent4",
		imgSrc: section4ImgSrc,
	},
];

const HOME_SUB_PART1_DATA = [
	{
		titleKey: "homeSubPart1Title1",
		contentKey: "homeSubPart1Content1",
	},
	{
		titleKey: "homeSubPart1Title2",
		contentKey: "homeSubPart1Content2",
	},
	{
		titleKey: "homeSubPart1Title3",
		contentKey: "homeSubPart1Content3",
	},
	{
		titleKey: "homeSubPart1Title4",
		contentKey: "homeSubPart1Content4",
	},
	{
		titleKey: "homeSubPart1Title5",
		contentKey: "homeSubPart1Content5",
	},
];

const HOME_SUB_PART2_DATA = [
	{
		titleKey: "homeSubPart2Title1",
		contentKey: "homeSubPart2Content1",
	},
	{
		titleKey: "homeSubPart2Title2",
		contentKey: "homeSubPart2Content2",
	},
	{
		titleKey: "homeSubPart2Title3",
		contentKey: "homeSubPart2Content3",
	},
	{
		titleKey: "homeSubPart2Title4",
		contentKey: "homeSubPart2Content4",
	},
	{
		titleKey: "homeSubPart2Title5",
		contentKey: "homeSubPart2Content5",
	},
];

type HomeProps = {
	setSelectedSection: Dispatch<SetStateAction<string>>;
	setSelectedProduct: Dispatch<SetStateAction<string>>;
};

const mobileDevice = isMobileDevice();

const Home = ({ setSelectedSection, setSelectedProduct }: HomeProps) => {
	const selectedLanguage = useSelector((state: any) => state.selectedLanguage.value);
	const translations = useSelector((state: any) => state.translations.value);

	const [homeProduct1ImgSrc, setHomeProduct1ImgSrc] = useState<string>();
	const [homeProduct2ImgSrc, setHomeProduct2ImgSrc] = useState<string>();

	useEffect(() => {
		getHomeProduct1ImgFile(selectedLanguage).then((fileUrl) => setHomeProduct1ImgSrc(fileUrl));
		getHomeProduct2ImgFile(selectedLanguage).then((fileUrl) => setHomeProduct2ImgSrc(fileUrl));
	}, []);

	const goToProduct = (product: string) => {
		setSelectedProduct(product);
		setSelectedSection("products");
	};

	return (
		<div id="home">
			<>
				<div className="container blackBackground">
					<div className="homeContent">
						{HOME_SECTIONS_DATA.map((data, idx) => (
							<div
								className="homeSection"
								style={{
									borderBottom:
										mobileDevice && idx < HOME_SECTIONS_DATA.length - 1
											? "1px solid"
											: "",
								}}
							>
								<div
									className="homeSectionTitleContainer"
									style={{
										gridTemplateColumns: mobileDevice ? "100%" : "70% 30%",
									}}
								>
									<div className="homeSectionTitle">
										<div
											className="homeSectionTitlePrefix"
											style={{ fontSize: mobileDevice ? "1.5em" : "3em" }}
										>
											{t(translations, selectedLanguage, "homeTitlePrefix")}
										</div>
										<div
											className="homeSectionTitleValue"
											style={{ fontSize: mobileDevice ? "1.5em" : "3em" }}
										>
											{t(translations, selectedLanguage, data.titleKey)}
										</div>
									</div>
									{!mobileDevice && (
										<div className="homeSectionImgContainer">
											<img className="homeSectionImg" src={data.imgSrc} />
										</div>
									)}
								</div>
								<div className="homeSectionContent">
									{t(translations, selectedLanguage, data.contentKey)}
								</div>
							</div>
						))}
					</div>
				</div>

				<div
					id="homeSubParts"
					style={{ gridTemplateColumns: !mobileDevice ? "50% 50%" : "" }}
				>
					<div
						className="homeSubPartTitle blackBackground"
						style={{ gridRow: mobileDevice ? "3" : "" }}
					>
						<img className="homeSubPartMainImg" src={cartozincLogoImgSrc} />
					</div>
					<div className="homeSubPartTitle" style={{ gridRow: mobileDevice ? "1" : "" }}>
						<div>
							<div className="homeSubPartMainTitle bigTitle">
								{t(translations, selectedLanguage, "homeSubPart2MainTitle")}
							</div>
							<div className="homeSubPartMainSubTitle subTitle">
								{t(translations, selectedLanguage, "homeSubPart2MainSubTitle")}
							</div>
						</div>
					</div>
					<div
						className="container blackBackground"
						style={{ gridRow: mobileDevice ? "4" : "" }}
					>
						<div className="homeContent">
							{HOME_SUB_PART1_DATA.map((data) => (
								<div className="homeSubPartContent">
									<div className="homeSubPartMain">
										<img className="homeSubPartImg" src={validIconImgSrc} />
										<div className="homeSubPartMainText subTitle">
											{t(translations, selectedLanguage, data.titleKey)}
										</div>
									</div>
									<div className="homeSubPartText">
										{t(translations, selectedLanguage, data.contentKey)}
									</div>
								</div>
							))}
						</div>
					</div>
					<div className="container" style={{ gridRow: mobileDevice ? "2" : "" }}>
						<div className="homeContent">
							{HOME_SUB_PART2_DATA.map((data) => (
								<div className="homeSubPartContent">
									<div className="homeSubPartMain">
										<img className="homeSubPartImg" src={invalidIconImgSrc} />
										<div className="homeSubPartMainText subTitle">
											{t(translations, selectedLanguage, data.titleKey)}
										</div>
									</div>
									<div className="homeSubPartText">
										{t(translations, selectedLanguage, data.contentKey)}
									</div>
								</div>
							))}
						</div>
					</div>
				</div>

				<div id="homeThridPart">
					<div id="homeThridPartTitle" className={mobileDevice ? "title" : "bigTitle"}>
						{t(translations, selectedLanguage, "homeThridPartTitle")}
					</div>
					<div>
						{homeProduct1ImgSrc ? (
							<img
								src={homeProduct1ImgSrc}
								className="homeThridPartProductImg pointer"
								onClick={() => goToProduct(PRODUCTS[0])}
							/>
						) : (
							<div
								className="homeThridPartProduct title pointer"
								style={{
									width: mobileDevice ? "250px" : "",
									paddingTop: mobileDevice ? "50px" : "70px",
								}}
								onClick={() => goToProduct(PRODUCTS[0])}
							>
								{PRODUCTS[0]}
							</div>
						)}
						{homeProduct2ImgSrc ? (
							<img
								src={homeProduct2ImgSrc}
								className="homeThridPartProductImg pointer"
								onClick={() => goToProduct(PRODUCTS[1])}
							/>
						) : (
							<div
								className="homeThridPartProduct title pointer"
								style={{
									width: mobileDevice ? "250px" : "",
									paddingTop: mobileDevice ? "50px" : "70px",
								}}
								onClick={() => goToProduct(PRODUCTS[1])}
							>
								{PRODUCTS[1]}
							</div>
						)}
					</div>
				</div>

				<div className="blackBackground">
					<Contact darkMode={true} />
				</div>
			</>

			<Button
				text={t(translations, selectedLanguage, "contact")}
				onClick={() => setSelectedSection("contact")}
				className="homeContactButton dark"
			/>
		</div>
	);
};
export default Home;
