import { getDownloadURL, ref } from "firebase/storage";

import { storage } from "./firebase";

export const getProductImgFile = (imgFileName: string) => {
	return new Promise<string>((resolve, reject) => {
		getDownloadURL(ref(storage, `productImg/${imgFileName}.png`))
			.then((url) => resolve(url))
			.catch(() => {});
	});
};

export const getHomeBackgroundImgFile = () => {
	return new Promise<string>((resolve, reject) => {
		getDownloadURL(ref(storage, "other/headerBackground.png"))
			.then((url) => resolve(url))
			.catch(() => {});
	});
};

export const getAuthorImgFile = () => {
	return new Promise<string>((resolve, reject) => {
		getDownloadURL(ref(storage, "other/profile.png"))
			.then((url) => resolve(url))
			.catch(() => {});
	});
};

export const getHomeProduct1ImgFile = (language: string) => {
	return new Promise<string>((resolve, reject) => {
		getDownloadURL(ref(storage, `other/homeProduct1${language}.png`))
			.then((url) => resolve(url))
			.catch(() => {});
	});
};

export const getHomeProduct2ImgFile = (language: string) => {
	return new Promise<string>((resolve, reject) => {
		getDownloadURL(ref(storage, `other/homeProduct2${language}.png`))
			.then((url) => resolve(url))
			.catch(() => {});
	});
};
