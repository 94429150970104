export const LANGUAGES = [
	{ code: "af", name: "Afrikaans" },
	{ code: "sq", name: "Albanian" },
	{ code: "am", name: "Amharic" },
	{ code: "ar", name: "Arabic" },
	{ code: "hy", name: "Armenian" },
	{ code: "as", name: "Assamese" },
	{ code: "az", name: "Azerbaijani" },
	{ code: "eu", name: "Basque" },
	{ code: "bn", name: "Bengali" },
	{ code: "bs", name: "Bosnian" },
	{ code: "br", name: "Breton" },
	{ code: "bg", name: "Bulgarian" },
	{ code: "my", name: "Burmese" },
	{ code: "ca", name: "Catalan" },
	{ code: "zh", name: "Chinese" },
	{ code: "hr", name: "Croatian" },
	{ code: "cs", name: "Czech" },
	{ code: "da", name: "Danish" },
	{ code: "nl", name: "Dutch" },
	{ code: "dz", name: "Dzongkha" },
	{ code: "en", name: "English" },
	{ code: "et", name: "Estonian" },
	{ code: "fa", name: "Persian" },
	{ code: "fil", name: "Filipino" },
	{ code: "fi", name: "Finnish" },
	{ code: "fr", name: "French" },
	{ code: "gl", name: "Galician" },
	{ code: "ka", name: "Georgian" },
	{ code: "de", name: "German" },
	{ code: "el", name: "Greek" },
	{ code: "gu", name: "Gujarati" },
	{ code: "ha", name: "Hausa" },
	{ code: "he", name: "Hebrew" },
	{ code: "hi", name: "Hindi" },
	{ code: "hu", name: "Hungarian" },
	{ code: "is", name: "Icelandic" },
	{ code: "ig", name: "Igbo" },
	{ code: "id", name: "Indonesian" },
	{ code: "ga", name: "Irish" },
	{ code: "it", name: "Italian" },
	{ code: "ja", name: "Japanese" },
	{ code: "kn", name: "Kannada" },
	{ code: "kk", name: "Kazakh" },
	{ code: "km", name: "Khmer" },
	{ code: "ko", name: "Korean" },
	{ code: "ku", name: "Kurdish" },
	{ code: "ky", name: "Kyrgyz" },
	{ code: "lo", name: "Lao" },
	{ code: "lv", name: "Latvian" },
	{ code: "lt", name: "Lithuanian" },
	{ code: "mk", name: "Macedonian" },
	{ code: "mg", name: "Malagasy" },
	{ code: "ms", name: "Malay" },
	{ code: "ml", name: "Malayalam" },
	{ code: "mt", name: "Maltese" },
	{ code: "mi", name: "Maori" },
	{ code: "mr", name: "Marathi" },
	{ code: "mn", name: "Mongolian" },
	{ code: "ne", name: "Nepali" },
	{ code: "no", name: "Norwegian" },
	{ code: "or", name: "Odia" },
	{ code: "ps", name: "Pashto" },
	{ code: "pl", name: "Polish" },
	{ code: "pt", name: "Portuguese" },
	{ code: "pa", name: "Punjabi" },
	{ code: "ro", name: "Romanian" },
	{ code: "ru", name: "Russian" },
	{ code: "gd", name: "Scottish Gaelic" },
	{ code: "sr", name: "Serbian" },
	{ code: "si", name: "Sinhala" },
	{ code: "sk", name: "Slovak" },
	{ code: "sl", name: "Slovenian" },
	{ code: "so", name: "Somali" },
	{ code: "st", name: "Southern Sotho" },
	{ code: "es", name: "Spanish" },
	{ code: "sw", name: "Swahili" },
	{ code: "sv", name: "Swedish" },
	{ code: "tg", name: "Tajik" },
	{ code: "ta", name: "Tamil" },
	{ code: "tt", name: "Tatar" },
	{ code: "te", name: "Telugu" },
	{ code: "th", name: "Thai" },
	{ code: "ti", name: "Tigrinya" },
	{ code: "tr", name: "Turkish" },
	{ code: "tk", name: "Turkmen" },
	{ code: "uk", name: "Ukrainian" },
	{ code: "ur", name: "Urdu" },
	{ code: "uz", name: "Uzbek" },
	{ code: "vi", name: "Vietnamese" },
	{ code: "cy", name: "Welsh" },
	{ code: "xh", name: "Xhosa" },
	{ code: "yi", name: "Yiddish" },
	{ code: "yo", name: "Yoruba" },
	{ code: "zu", name: "Zulu" },
];
